<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row wrap align-center>
      <v-flex xs12>
        <v-card-title>
          Teacher Management Report
          <v-spacer></v-spacer>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-row>
              <v-col v-if="allowRegionFilter" cols="12">
                <v-select v-model="selectRegion" :items="regions" item-text="Name" item-value="RegionID" label="Region" prepend-icon="mdi-earth" 
                @change="selectCentre = null"
                clearable></v-select>
              </v-col>
              <v-col v-if="allowCentreFilter" cols="12">
                <v-select v-model="selectCentre" :items="filteredCentres" item-text="Name" item-value="CentreID" label="Campus" prepend-icon="mdi-home-outline" 
                clearable></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="givenname" prepend-icon="mdi-magnify" label="Given Name" clearable></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="surname" prepend-icon="mdi-magnify" label="Surname" clearable></v-text-field>
              </v-col> 
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="fromdate"
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="fromDateFormatted"
                      label="From date marked"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      @blur="fromdate = parseDate(fromDateFormatted)"
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fromdate" no-title @input="menuFromDate = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="todate"
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="toDateFormatted"
                      label="To date marked"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      @blur="todate = parseDate(toDateFormatted)"
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="todate" no-title @input="menuToDate = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-btn color="primary" class="mr-4" @click="Search" :loading="searchLoading">Search</v-btn>
            </v-row>
          </v-card-text>
        </v-form>
      </v-flex>
      <v-flex xs12 v-if="showTable">
        <v-card>
          <v-card-title>
            Results
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-card-subtitle>
            {{fromDateFormatted}} - {{toDateFormatted}}
          </v-card-subtitle>
          <v-data-table multi-sort :headers="headers" :items="results" item-key="name" :search="search" :custom-filter="customFilter" :loading="searchLoading" loading-text="Loading... Please wait">
            <template v-slot:[`body.append`]="{headers}">
              <tr>
                <td v-for="(header,i) in headers" :key="i">
                  <div v-if="header.value == 'GivenName'">
                    <p class="subtitle-1 font-weight-bold">Total</p>
                  </div>
                  <div v-if="header.value == 'MarkedCount'">
                    <p class="subtitle-1 text-center font-weight-bold">{{totals && totals.MarkedCountTotal}}</p>
                  </div>
                  <div v-if="header.value == 'AwardCount'">
                    <p class="subtitle-1 text-center font-weight-bold">{{totals && totals.AwardCountTotal}}</p>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>       
      </v-flex>
    </v-layout>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-container> 
</template>

<script>
import { httpClient } from '../services/http-client';
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  name: 'ReportTeacherManagement',
  data () {
    return {
      headers: [
        { text: `Teacher's Given Name `, value: 'GivenName' },
        { text: `Teacher's Surname`, value: 'Surname' },
        { text: `Role`, value: 'RoleName' },
        { text: 'Region', value: 'RegionName' },
        { text: 'Campus', value: 'CentreName' },
        { text: 'Term', value: 'TermName' },
        { text: 'Year', value: 'HomeworkYear' },
        { text: 'Week', value: 'WeekID' },
        { text: 'Subject', value: 'SubjectName' },
        { text: 'Number of files marked', align: 'center', value: 'MarkedCount' },
        { text: 'Number of awards given', align: 'center', value: 'AwardCount' },
      ],
      search: null,
      searchLoading: false,
      fromdate: null,
      fromDateFormatted: null,
      menuFromDate: false,
      todate: null,
      toDateFormatted: null,
      menuToDate: false,
      showTable: false,
      results: [],
      givenname: null,
      surname: null,
      totals: null,
      selectRegion: null,
      selectCentre: null,
      overlay: false,
    }
  },
  computed: {
    filteredCentres() {
      if (this.selectRegion)
        return this.centres.filter(c => c.RegionID === this.selectRegion);
      return this.centres;
    },
    computedFromDateFormatted () {
      return this.formatDate(this.fromdate)
    },
    computedToDateFormatted () {
      return this.formatDate(this.todate)
    },
    ...mapGetters(['allowRegionFilter', 'allowCentreFilter']),
    ...mapState(['user', 'regions', 'centres'])
  },
  watch: {
    fromdate () {
      this.fromDateFormatted = this.formatDate(this.fromdate)
    },
    todate () {
      this.toDateFormatted = this.formatDate(this.todate)
    },
  },
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        this.overlay = true;
        await Promise.all([
        this.$store.dispatch('getRegions'),
        this.$store.dispatch('getCentres')]);
      } finally {
        this.overlay = false;
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async Search() {
      try {
        this.showTable = true;
        this.searchLoading = true;
        const response = await httpClient.get('/report/teachermanagement', {
          params: {
            RegionID: this.selectRegion,
            CentreID: this.selectCentre,
            GivenName: this.givenname,
            Surname: this.surname,
            FromDate: this.fromdate && this.fromdate.toString(),
            ToDate: this.todate && this.todate.toString()
          }
        });
        if (response && response.data) {
          this.results = response.data.Rows;
          this.totals = response.data.Totals;
        } else {
          this.results = [];
        }
      } finally {
        this.searchLoading = false;
      }
    },
    customFilter (value, search, item) {
      return value != null &&
        search != null &&
        ( value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        this.$options.filters.formatDate(item.DateMarked).toString().indexOf(search) !== -1
        )
    },
  }
}
</script>

<style scoped></style>
